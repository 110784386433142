<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="d-flex flex-row content-container" style="margin-top: 13vh">
      <div
        class="consigne"
        style="
          height: 70px;
          min-width: 295px;
          margin-left: -110px;
          margin-right: -80px;
        "
      >
        <div
          class="text-center"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_1,
            fontSize: WEBAPPPRO_FONT_SIZE_1,
            fontFamily: WEBAPPPRO_FONT_FAMILY_1,
            fontWeight: 'regular',
            'text-transform': 'uppercase',
          }"
        >
          <p>Votre signature</p>
        </div>
        <div
          class="text-center"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_2,
            fontSize: WEBAPPPRO_FONT_SIZE_3,
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            'font-weight': 'regular',
          }"
        >
          <p>Signez avec votre doigt à l'intérieur du cadre</p>
        </div>
      </div>
      <div class="d-flex justify-center mb-4 mt-5">
        <drawer
          ref="drawer"
          :style="{
            height: '70vh',
          }"
          :clear.sync="clearCanvas"
          @empty="canvasEmpty = $event"
          @end="send"
        ></drawer>
      </div>
      <div
        class="d-flex flex-row align-center consigne"
        style="margin-left: -230px"
      >
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          class="mt-4"
          :disabled="canvasEmpty || uploading"
          @click="validate"
          :loading="uploading"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginTop: '10px',
            padding: '25px 100px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '20px',
            width: '260px',
            height: '52px',
          }"
          >Valider</v-btn
        >
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_2"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_2,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_2,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginBottom: '-14px',
            padding: '25px 100px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '10px',
            width: '260px',
            height: '52px',
            marginLeft: '20px',
          }"
          :disabled="canvasEmpty || uploading"
          @click="$refs.drawer.clearCanvas()"
        >
          Recommencer
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from '@/components/Drawer'
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Signature',
  data() {
    return {
      clearCanvas: false,
      canvasEmpty: true,
      uploading: false,
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
    }
  },
  components: { Drawer },
  methods: {
    validate() {
      this.uploading = true
      this.$refs.drawer.validateCanvas()
      //this.$router.push({ name: 'TreatmentPhoto' })
    },
    redirectPhotoShooter() {
      this.$router.push({
        name: 'PhotoShooter',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    async send({ signatureUrl }) {
      if (
        localStorageService.getValue('rejectStatus') === 'rejected_signature'
      ) {
        //Only signature to Redo
        //Signature save
        const signatureFormData = new FormData()
        const signatureBlob = await (await fetch(signatureUrl)).blob()
        signatureFormData.append('file', signatureBlob)
        signatureFormData.append('type', 'signature')

        const signaturePromise = requestService
          .post(`/file/upload`, signatureFormData)
          .then(data => {
            localStorageService.setObject('signatureUid', data.data.uid)
          })

        Promise.all([signaturePromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              status: 'pending_verification',
              signature: {
                status: 'pending_verification',
                newSignatureUid: localStorageService.getValue('signatureUid'),
              },
            })
            requestService
              .post(
                '/document/' +
                  localStorageService.getValue('documentId') +
                  '/update',
                orderFormData,
                {},
                {
                  'content-type': 'application/json',
                  shortlive_api_key:
                    localStorageService.getValue('shortliveApiKey'),
                },
              )
              .then(() => {
                this.uploading = false
                this.$router.push({ name: 'Updated' })
              })
              .catch(() => {
                this.$store.dispatch(
                  'alert/error',
                  'Erreur lors de la création de la commande.',
                )
                this.uploading = false
                this.$router.push({ name: 'NotAuthorized' })
              })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors de l'envoi de vos documents.",
            )
            this.uploading = false
            this.$router.push({ name: 'NotAuthorized' })
          })
      } else {
        //Signature save
        const signatureFormData = new FormData()
        const signatureBlob = await (await fetch(signatureUrl)).blob()
        signatureFormData.append('file', signatureBlob)
        signatureFormData.append('type', 'signature')

        const signaturePromise = requestService
          .post(`/file/upload`, signatureFormData)
          .then(data => {
            localStorageService.setObject('signatureUid', data.data.uid)
          })

        //Photo save
        const photoFormData = new FormData()
        const photoBlob = await (
          await fetch(localStorageService.getValue('photoUrl'))
        ).blob()
        photoFormData.append('file', photoBlob)
        photoFormData.append('type', 'photo')

        Promise.all([signaturePromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              orderItems: [
                {
                  quantity: 1,
                  document: {
                    photo: localStorageService.getValue('photoUid'),
                    signature: localStorageService.getValue('signatureUid'),
                  },
                  priceId: localStorageService.getValue('priceId'),
                },
              ],
            })
            requestService
              .post(
                `/order/stripeCheckout`,
                orderFormData,
                {},
                {
                  'content-type': 'application/json',
                  shortlive_api_key:
                    localStorageService.getValue('shortliveApiKey'),
                },
              )
              .then(data => {
                localStorageService.setObject('orderId', data.data.id)
                localStorageService.setObject(
                  'checkoutSessionId',
                  data.data.checkoutSessionId,
                )
                if (data.data.amount == 0) {
                  this.uploading = false
                  this.$router.push({ name: 'Final' })
                } else if (
                  data.data.amount == null ||
                  data.data.amount == 'null'
                ) {
                  this.uploading = false
                  this.$router.push({ name: 'AlertAgency' })
                } else {
                  this.uploading = false
                  this.$router.push({ name: 'Payment' })
                  //this.$router.push({ name: 'TreatmentPhoto' })
                }
              })
              .catch(() => {
                this.$store.dispatch(
                  'alert/error',
                  'Erreur lors de la création de la commande.',
                )
                this.uploading = false
                this.$router.push({ name: 'NotAuthorized' })
              })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors de l'envoi de vos documents.",
            )
            this.uploading = false
            this.$router.push({ name: 'NotAuthorized' })
          })
      }
    },
  },
  mounted() {
    if (localStorageService.getValue('rejectStatus') !== 'rejected_signature') {
      if (localStorageService.getValue('shortliveApiKey') === undefined) {
        this.uploading = false
        this.$router.push({ name: 'NotAuthorized' })
      } else if (localStorageService.getValue('photoUrl') === undefined) {
        this.uploading = false
        this.$router.push({ name: 'ShootPhoto' })
      }
    }
  },
}
</script>

<style scoped>
.background-container {
  display: flex;
  flex-direction: column;
}

.mobile-only {
  margin: 10px;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.landscape-mode {
  writing-mode: sideways-lr;
}
.consigne {
  transform: rotate(270deg);
  transform-origin: center center;
  align-items: center;
  justify-content: center;
  display: inline-block;

  /*height: 100vh;*/
}

@media screen and (max-width: 600px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 90%;
  }
}
</style>
